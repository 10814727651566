<template>
    <div
        class="SupportChat"
        :class="{ mobile: isMobile }"
    >

        <button
            v-if="isMobile"
            @click="$emit('collapse')"
            class="wv-btn--transparent wv-btn--text wv-btn--block mb-20">
            <SvgUse :width="19" :height="19" id="back"></SvgUse>
            {{ $lng.__('lk_webmaster_main', 'Ticket list') }}
        </button>

        <div class="SupportChat__subject">
            <div class="SupportChat__subject__title">
                <strong>{{ $lng.__('lk_webmaster_main', 'Subject') }}:</strong> {{ ticket.subject }}
            </div>
            <div class="SupportChat__subject__id">
                {{ $lng.__('lk_webmaster_main', 'Ticket ID') }}: {{ ticket.id }}
            </div>
        </div>

        <Card>

            <div
                v-if="!isLoading"
                class="SupportChat__head">
                <div class="SupportChat__head__manager">
                    <img :src="ticket.manager.avatar" :alt="ticket.manager.name">
                    {{ ticket.manager.name }}
                </div>
                <button
                    v-if="isTicketNotClosed && !isLoading"
                    @click="$emit('close')"
                    class="wv-btn--gray wv-btn--text">
                    {{ $lng.__('lk_webmaster_main', 'Close ticket') }}
                </button>
            </div>

            <div
                v-if="!isLoading"
                class="card__separator"
            ></div>

            <Loading v-if="isLoading"></Loading>

            <div
                v-if="!isLoading"
                class="SupportChat__messages">

                <div
                    v-for="message in ticket.messages"
                    class="SupportChat__message">

                    <div class="SupportChat__message__date">
                        <span>{{ message.timestamp }}</span>
                    </div>

                    <div
                        :class="{ from_manager: message.from_manager }"
                        class="SupportChat__message__content">
                        <div
                            v-if="message.from_manager"
                            class="SupportChat__message__avatar">
                            <img
                                :src="ticket.manager.avatar"
                                :alt="ticket.manager.name">
                        </div>
                        <div
                            :class="{ from_manager: message.from_manager }"
                            class="SupportChat__message__text">
                            {{ message.message }}
                        </div>

                    </div>
                </div>

            </div>


            <template v-if="!isLoading && isTicketNotClosed">
                <Loading v-if="isLoadingTicketMessage"></Loading>
                <div class="card__separator mb-30 mt-20 hidden visible--sm-up"></div>
                <div
                    v-if="!isLoadingTicketMessage"
                    class="SupportChat__form">

                    <div
                        v-if="isNewMessageAlertVisibe"
                        class="SupportChat__form__alert">
                        <Alert
                            :message="$lng.__('lk_webmaster_main', 'Enter the message')"
                        ></Alert>
                    </div>

                    <div class="SupportChat__form__wrapper">
                        <div class="SupportChat__form__content">
                            <FormTextarea
                                v-model="newMessage"
                                :placeholder="$lng.__('lk_webmaster_main', 'Enter the message')"
                            ></FormTextarea>
                        </div>
                        <div class="SupportChat__form__btn">
                            <button
                                @click="addMessageToticket"
                                class="wv-btn--purpure">
                                <SvgUse :width="20" :height="20" id="send"></SvgUse>
                            </button>
                        </div>
                    </div>



                </div>
            </template>

        </Card>
    </div>
</template>

<script>
export default {
    name: 'SupportChat',
    props: [
        'ticket',
        'isLoading',
        'isLoadingTicketMessage',
    ],
    data() {
        return {
            newMessage: '',
            isNewMessageAlertVisibe: false,
        };
    },
    watch: {
        newMessage(val) {
            if (val.trim().length > 0) {
                this.isNewMessageAlertVisibe = false
            }
        }
    },
    computed: {
        isTicketNotClosed() {
            return this.ticket.status !== 'Close';
        },
        isTicketClosed() {
            return this.ticket.status === 'Close';
        },
        isMobile() {
            return WV.isMobile()
        },
    },
    methods: {
        addMessageToticket() {
            if (this.newMessage.trim().length < 1) {
                this.isNewMessageAlertVisibe = true
            } else {
                this.$emit('addMessage', this.newMessage);
                this.newMessage = '';
            }

        },
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.SupportChat {
    width: calc(100% - 400px - 30px);
    &.mobile{
        width: 100%;
    }
    &__subject {
        background: $color-day-line;
        border-radius: 10px 10px 0 0;
        margin-bottom: -10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 20px 20px;
        &__title {
            color: $color-day-dark-gray2;
            font-size: 12px;
            font-family: $font-secondary;
            word-break: break-word;
            margin-right: 20px;
            @include break(md) {
                font-size: 16px;
            }
        }
        &__id {
            font-family: $font-secondary;
            background: $color-gray2;
            color: white;
            text-transform: uppercase;
            padding: 5px 15px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            border-radius: 12.5px;
        }
    }
    &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 20px 0;
        margin-top: -10px;
        &__manager {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            font-weight: 700;
            font-family: $font-primary;
            font-size: 14px;
            @include break(sm) {
                font-size: 16px;
            }
            img {
                width: 40px;
                height: 40px;
                margin-right: 20px;
            }
        }
    }
    &__message {
        &__date {
            display: block;
            position: relative;
            width: 100%;
            height: 30px;
            text-align: center;
            line-height: 30px;
            margin: 30px 0;
            &::before {
                content: '';
                width: 100%;
                height: 1px;
                background: $color-day-line;
                position: absolute;
                top: 14px;
                left: 0;
                right: 0;
                z-index: 1;
            }
            span {
                display: inline-block;
                background: white;
                z-index: 3;
                position: relative;
                height: 30px;
                padding: 0 20px;
                font-family: $font-secondary;
                font-size: 14px;
                color: $color-text-muted;
            }
        }
        &__content {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            @include break(sm){
                padding: 0 0 0 30%;
            }
            &.from_manager {
                justify-content: flex-start;
                align-items: flex-end;
                @include break(sm){
                    padding: 0 30% 0 0;
                }
            }
        }
        &__avatar {
            width: 30px;
            height: 30px;
            flex: none;
            margin-right: 5px;
        }
        &__text {
            font-family: $font-secondary;
            font-size: 14px;
            background: $color-day-light-blue;
            padding: 10px 25px;
            color: white;
            border-radius: 25px 25px 3px 25px;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: break-word;
            &.from_manager {
                color: $color-text-default;
                background: $color-day-table;
                border-radius: 25px 25px 25px 3px;
            }
        }
    }
    &__form {
        position: fixed;
        z-index: 999;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        padding: 10px;
        @include break(sm) {
            position: relative;
        }
        &__alert{
            padding-bottom: 10px;
        }
        &__wrapper{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }
        &__content {
            width: 100%;
            padding-right: 10px;
        }
    }
}
</style>