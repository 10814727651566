<template>
    <Modal
        :title="$lng.__('lk_webmaster_main', 'New ticket')"
        @close="close()">

        <div class="ticketCreate">

            <Loading v-if="isLoading"></Loading>

            <template v-if="!isLoading">

                <FormInput
                    :label="false"
                    :placeholder="$lng.__('lk_webmaster_main', 'Ticket subject')"
                    v-model="ticket.subject"
                    :validations="[
                        {
                            rule: $validations.isStringNotEmpty(ticket.subject),
                            message: $lng.__('lk_webmaster_main', 'Please enter ticket subject')
                        },
                        {
                            rule: $validations.isStringLengthSmallThan(ticket.subject, 100),
                            message: $lng.__('lk_webmaster_main', 'Ticket subject must be less than 100 characters')
                        },
                    ]"
                ></FormInput>

                <textarea
                    class="textarea mt-20 mb-20"
                    rows="10"
                    v-model="ticket.message"
                    :placeholder="$lng.__('lk_webmaster_main', 'Write a message')"
                ></textarea>

                <Alert
                    addclass="mb-30"
                    v-if="isValidationMessageVisible && ticketTextValidationMessage!==''"
                    :message="ticketTextValidationMessage"
                ></Alert>

                <button
                    @click="createTicket"
                    class="wv-btn--blue ticketCreate__btn">
                    {{ $lng.__('lk_webmaster_main', 'Create ticket') }}
                </button>

            </template>

        </div>


    </Modal>
</template>

<script>
export default {
    name: 'TicketCreate',
    props: [],
    data() {
        return {
            ticket: {
                subject: '',
                message: '',
            },
            isLoading: false,
            isTickerCreated: false,
            isValidationMessageVisible: false,
        }
    },
    computed: {
        isTicketValid() {
            return this.$validations.isStringNotEmpty(this.ticket.subject)
                && this.$validations.isStringLengthSmallThan(this.ticket.subject, 100)
                && this.$validations.isStringLengthBiggerThan(this.ticket.message, 1)
                && this.$validations.isStringLengthSmallThan(this.ticket.message, 500);
        },
        ticketTextValidationMessage() {
            let message = ''
            if (!this.$validations.isStringLengthBiggerThan(this.ticket.message, 1)) {
                message = this.$lng.__('lk_webmaster_main', 'Enter ticket message');
            }
            if (!this.$validations.isStringLengthSmallThan(this.ticket.message, 500)) {
                message = this.$lng.__('lk_webmaster_errors', 'Ticket message must be smaller than 500 symbols');;
            }
            return message
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        ticketCreated() {
            this.ticket.subject = ''
            this.ticket.message = ''
            this.$emit('created')
            this.isTickerCreated = true
        },
        showValidationMessage() {
            this.isValidationMessageVisible = true
            setTimeout(this.hideValidationMessage, 5000)
        },
        hideValidationMessage() {
            this.isValidationMessageVisible = false
        },

        createTicket() {
            WV.Bus.emit('fireValidations')
            if (this.isTicketValid) {
                this.isLoading = true
                this.$store.dispatch('webmaster/createTicket', this.ticket).then(() => {
                    this.ticketCreated()
                    this.isLoading = false
                    this.close()
                })
            } else {
                this.isValidationMessageVisible = true
            }


        },

    },
    beforeMount() {
    }
};
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .ticketCreate{
        width: 100%;
        margin: 40px auto 0;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &__btn{
            width: calc(100% + 10px);
        }
    }
</style>