<template>
    <div class="Support">

        <Loading v-if="isLoadingList && !isMobile"></Loading>

        <Card v-if="!tickets.length && !isLoadingList">
            <button
                @click="isShowCreateTicketForm=true"
                class="wv-btn--blue wv-btn--text wv-btn--block">
                {{ $lng.__('lk_webmaster_main', 'New ticket') }}
            </button>
        </Card>

        <div
            class="SupportTickets"
            :class="{ mobile: isMobile }"
            v-if="(!isMobile || !activeTicket) && tickets.length">
            <Card>

                <div class="SupportTickets__head">
                    <h3>{{ $lng.__('lk_webmaster_main', 'All tickets') }}</h3>
                    <button
                        @click="isShowCreateTicketForm=true"
                        class="wv-btn--blue wv-btn--text">
                        {{ $lng.__('lk_webmaster_main', 'New ticket') }}
                    </button>
                </div>

                <div
                    class="SupportTickets__opened"
                    v-if="!isLoadingList && ticketsOpened.length">

                    <div class="SupportTickets__opened__label">
                        <span>{{ $lng.__('lk_webmaster_main', 'Active tickets') }} ({{ ticketsOpened.length }})</span>
                    </div>

                    <div class="SupportTickets__opened__list">
                        <div
                            class="SupportTickets__opened__item"
                            @click="expandTicketToChat(ticket)"
                            v-for="ticket in ticketsOpened">
                            <div class="SupportTickets__opened__head">
                                <div class="SupportTickets__opened__manager">
                                    <img
                                        :src="ticket.manager.avatar"
                                        :alt="ticket.manager.name">
                                    <div>
                                        {{ ticket.manager.name }}
                                        <div class="SupportTickets__opened__subject">
                                            {{ ticket.subject }}
                                        </div>
                                    </div>
                                </div>
                                <div class="SupportTickets__opened__time">
                                    {{ new Date(ticket.last_message.timestamp).getFormattedDate() }}
                                </div>
                            </div>
                            <div class="SupportTickets__opened__message">
                                {{
                                    ticket.last_message.message.length > 200
                                    ? ticket.last_message.message.slice(0, 150).concat('...')
                                    : ticket.last_message.message
                                }}
                            </div>
                        </div>
                    </div>
                </div>

            </Card>

            <div
                v-if="ticketsClosed.length"
                class="SupportTickets__closed">

                <div class="SupportTickets__closed__label">
                    <span>{{ $lng.__('lk_webmaster_main', 'Closed tickets') }} ({{ ticketsClosed.length }})</span>
                </div>

                <div class="SupportTickets__closed__list">
                    <div
                        class="SupportTickets__closed__item"
                        @click="expandTicketToChat(ticket)"
                        v-for="ticket in ticketsClosed">
                        <div class="SupportTickets__closed__head">
                            <div class="SupportTickets__closed__manager">
                                <img
                                    :src="ticket.manager.avatar"
                                    :alt="ticket.manager.name">
                                <div>
                                    {{ ticket.manager.name }}
                                    <div class="SupportTickets__closed__subject">
                                        {{ ticket.subject }}
                                    </div>
                                </div>
                            </div>
                            <div class="SupportTickets__closed__time">
                                {{ new Date(ticket.last_message.timestamp).getFormattedDate() }}
                            </div>
                        </div>
                        <div class="SupportTickets__closed__message">
                            {{ ticket.last_message.message }}
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <SupportChat
            v-if="activeTicket"
            :ticket="activeTicket"
            :isLoading="isLoadingTicket"
            :isLoadingTicketMessage="isLoadingTicketMessage"
            @close="closeTicket()"
            @collapse="activeTicket=false"
            @addMessage="addMessageToActiveTicket"
        ></SupportChat>

        <TicketCreate
            v-if="isShowCreateTicketForm"
            @close="isShowCreateTicketForm=false"
            @created="loadTicketsList()"
        ></TicketCreate>

    </div>
</template>

<script>

import SupportChat from './SupportChat.vue';
import TicketCreate from './TicketCreate.vue';

export default {
    name: 'Support',
    props: [],
    components: {
        SupportChat,
        TicketCreate,
    },
    data() {
        return {
            activeTicket: false,
            messages: [],
            isLoadingList: true,
            isLoadingTicket: true,
            isLoadingTicketMessage: false,
            isShowCreateTicketForm: false,
            isMobile: false,
        };
    },
    computed: {
        tickets() {
            return collect(this.$store.state.webmaster.tickets)
                .sortBy(ticket => {
                    let sort = ticket.status === 'Close' ? '1' : '2';
                    sort = sort + sort.id;
                    return sort;
                })
                .all();
        },
        ticketsOpened() {
            return collect(this.tickets)
                .whereNotIn('status', ['Close'])
                .all();
        },
        ticketsClosed() {
            return collect(this.tickets)
                .whereIn('status', ['Close'])
                .all();
        },
    },
    watch: {
        isMobile(val) {
            if (!val && !this.activeTicket) {
                this.expandTicketToChat(this.tickets[0]);
            }
        }
    },
    methods: {
        expandTicketToChat(ticket) {
            this.activeTicket = ticket;
            this.isLoadingTicket = true;

            document.getElementById('app').scrollIntoView(({behavior: 'smooth', block: 'start', inline: 'start'}));

            this.$store.dispatch('webmaster/getTicket', ticket.id)
                .then(ticket => {
                    this.activeTicket = ticket;
                    this.isLoadingTicket = false;
                    this.$store.dispatch('auth/getUser');
                });
        },
        isTicketActive(ticket) {
            return this.activeTicket.id === ticket.id;
        },
        loadTicketsList() {
            this.isLoadingList = true;
            this.$store.dispatch('webmaster/getTickets')
                .then(() => {
                    this.isLoadingList = false;
                    if (this.tickets.length) {
                        if (!this.isMobile) {
                            this.expandTicketToChat(this.tickets[0]);
                        }
                    } else {
                        this.isShowCreateTicketForm = true;
                    }
                });
        },
        closeTicket() {
            this.activeTicket.status = 'Close';
            this.$store.dispatch('webmaster/putTicket', this.activeTicket)
                .then(response => {
                    if (this.isMobile) {
                        this.activeTicket = false
                    }
                    this.loadTicketsList();
                    this.$store.dispatch('auth/getUser');
                });
        },
        addMessageToActiveTicket(message) {
            this.isLoadingTicketMessage = true;
            this.$store.dispatch('webmaster/createTicketMessage', {
                    ticketId: this.activeTicket.id,
                    message: message
                })
                .then(ticket => {
                    this.isLoadingTicketMessage = false;
                    this.activeTicket = ticket;
                });
        },
        checkIsMobile() {
            this.isMobile = WV.isMobile();
        },
    },
    beforeMount() {
        this.checkIsMobile();
        window.addEventListener('resize', this.checkIsMobile);
        this.loadTicketsList();
        this.$store.dispatch('auth/getUser');
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.Support {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }
    &Tickets {
        width: 400px;
        overflow: hidden;
        &.mobile {
            width: 100%;
        }
        &__head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding-bottom: 20px;
            margin: -10px 0 0 0;
            h3 {
                width: 100%;
            }
        }
        &__opened {
            &__label {
                text-align: center;
                font-weight: 600;
                padding: 0 20px;
                line-height: 37px;
                font-size: 14px;
                color: $color-day-gray;
                position: relative;
                margin: 0 -30px;
                &::before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: $color-day-line;
                    position: absolute;
                    top: 18px;
                    left: 0;
                    right: 0;
                    z-index: 1;
                }
                span {
                    display: inline-block;
                    position: relative;
                    z-index: 3;
                    background: white;
                    padding: 0 15px;
                }
            }
            &__head {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
            }
            &__item {
                cursor: pointer;
                padding: 40px 0 0;
            }
            &__manager {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-weight: 700;
                color: $color-text-default;
                font-size: 12px;
                line-height: 15px;
                img {
                    width: 40px;
                    height: 40px;
                    flex: none;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 20px;
                }
            }
            &__subject {
                color: $color-day-gray;
                line-height: 16px;
                padding-top: 5px;
                word-break: break-word;
            }
            &__time {
                font-weight: normal;
                font-family: $font-secondary;
                font-size: 12px;
                color: $color-text-muted;
                text-align: right;
                white-space: nowrap;
            }
            &__message {
                padding-top: 20px;
                font-size: 12px;
                line-height: 16px;
                font-family: $font-secondary;
                color: $color-text-default;
                word-break: break-word;
            }
        }
        &__closed {
            opacity: .8;
            &__label {
                text-align: center;
                width: 100%;
                padding: 25px 0;
                span {
                    font-weight: 600;
                    display: inline-block;
                    padding: 0 20px;
                    line-height: 37px;
                    background: white;
                    border-radius: 100px;
                    font-size: 14px;
                    color: $color-day-gray;
                }
            }
            &__item {
                background: white;
                cursor: pointer;
                margin: 5px 0 0;
                border-radius: 10px;
                padding: 20px;
            }
            &__head {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
            }
            &__manager {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-weight: 700;
                color: $color-text-default;
                font-size: 12px;
                line-height: 15px;
                img {
                    width: 40px;
                    height: 40px;
                    flex: none;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 20px;
                }
            }
            &__subject {
                color: $color-day-gray;
                line-height: 16px;
                padding-top: 5px;
                word-break: break-word;
            }
            &__time {
                font-weight: normal;
                font-family: $font-secondary;
                font-size: 12px;
                color: $color-text-muted;
                text-align: right;
            }
            &__message {
                padding-top: 20px;
                font-size: 12px;
                line-height: 16px;
                font-family: $font-secondary;
                word-break: break-word;
                color: $color-text-default;
            }
        }
        &__item {
            position: relative;
            margin: 0 -30px;
            padding: 20px 30px;
            &::after {
                display: block;
                position: absolute;
                content: '';
                background: $color-day-line;
                height: 1px;
                bottom: -1px;
                right: -30px;
                left: 0;
            }
            &:hover {
                background: $color-day-table;
                cursor: pointer;
            }
            &.closed {
                opacity: 70%;
            }
            &.active {
                background: $color-day-bg;
                cursor: default;
                &.closed {
                    opacity: 100%;
                }
            }
            &__head {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-family: $font-secondary;
                font-size: 12px;
                letter-spacing: 0.2px;
            }
            &__id {
            }
            &__status {
            }
            &__body {
                display: flex;
                flex-direction: row;
                padding-top: 10px;
            }
            &__avatar {
                width: 70px;
                height: 70px;
                background: $color-day-table;
                border-radius: 50%;
                overflow: hidden;
                flex: none;
                margin-right: 35px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            &__manager {
                font-family: $font-primary;
                font-weight: 600;
                padding-bottom: 10px;
                @extend .fix-montserrat;
            }
            &__subject {
                font-family: $font-secondary;
                font-size: 16px;
                color: $color-text-muted;
            }
        }
    }

}
</style>